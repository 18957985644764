import type { JSXElement } from "solid-js";
import { createSignal, createUniqueId, onMount } from "solid-js";

interface TooltipProps {
  children: JSXElement;
  tip: string | JSXElement;
  forceLeft?: boolean;
  width?: number;
}

export default function ToolTipHTML(props: TooltipProps) {
  const id = createUniqueId();
  const [tipWidth, setTipWidth] = createSignal(0);
  const [tipIsActive, setTipIsActive] = createSignal(false);

  onMount(() => {
    if (document.getElementById(id)) {
      setTipWidth(document.getElementById(id)!.clientWidth);
    }
  });

  const customStyles = () => {
    let styles = "";

    styles += props.forceLeft
      ? "left: 0;"
      : "right: calc(50% - " + tipWidth() / 2 + "px)";

    if (props.width) {
      styles += "width: " + props.width + "px;";
    }

    return styles;
  };

  return (
    <>
      <span
        class="cog-tooltip html"
        classList={{ active: tipIsActive() }}
        onClick={(e) => {
          const els = document.getElementsByClassName("cog-tooltip");
          Array.prototype.forEach.call(els, function (el) {
            if (el.id != id) {
              el.classList.remove("active");
            }
          });
          setTipIsActive(!tipIsActive());
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {props.children}
        <div class="tip" id={id} style={customStyles()} data-test="tooltip">
          {props.tip}
        </div>
      </span>
    </>
  );
}
